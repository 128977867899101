@import '../sassVariables.scss';

// create dynamic breakpoints specifically for the recipe grid.
$icon-grid-4: calc((#{$recipe-icon-size} * 5) + (#{$recipe-icon-padding} * 4) + (#{$margin-lg} * 2));
$icon-grid-3: calc((#{$recipe-icon-size} * 4) + (#{$recipe-icon-padding} * 3) + (#{$margin-lg} * 2));
$icon-grid-2: calc((#{$recipe-icon-size} * 3) + (#{$recipe-icon-padding} * 2) + (#{$margin-lg} * 2));
$icon-grid-1: calc((#{$recipe-icon-size} * 2) + (#{$recipe-icon-padding} * 1) + (#{$margin-lg} * 2));

$completed-icon-width: 35px; 

//HEADER SECTION
.headerSection {
  background-image: url('../../assets/images/header_background.jpg');
  padding-top: $navbar-height;
  position: relative;
  z-index: 500;
  height: 35vh;
  max-height: 700px;
  min-height: 110px;
  background-size: 100%;
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  box-shadow: 0px -1px 5px 1px rgba(0,0,0,0.28);
};

.headerSection.discoverPage {
  background-image: url('../../assets/images/discover_background.jpg');
}

@media (max-width: $small-breakpoint) {
  .headerSection {
    height: $header-height-sm;
  }
}
@media (min-width: $small-breakpoint+1) and (max-width: $medium-breakpoint) {
  .headerSection {
    height: $header-height-md;
  }
}
@media (min-width: $medium-breakpoint+1) and (max-width: $large-breakpoint) {
  .headerSection {
    height: $header-height-lg;
  }
}
@media (max-width: $large-breakpoint) {
  .headerSection {
      padding-top: $navbar-height-md;
  }
}

//RECIPE GRID

.headline {
  font-weight: 400;
  font-size: 2.5rem;
  padding: 3rem 0;
  text-align: center;
}

.recipeItemBox {
  width: $recipe-icon-size;
  box-sizing: border-box !important;
  word-wrap: break-word;
  a {
    cursor: pointer;
  }
  h3 {
    box-sizing: border-box;
    font-size: 17px;
    margin: 0;
    color: $offsetColor;
    font-weight: 400;
  }
  border-radius: $icon-border-radius / 2; 
  .ratingBox {
    font-size: 1.4rem;
  }
}

.recipeItemImageContainer {
  width: $recipe-icon-size;
  height: $recipe-icon-size;
  position: relative;
}

.recipeBottomSection {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
}

.recipeItemStyles {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-template-rows: auto;
  justify-content: center;
  grid-column-gap: $recipe-icon-padding;
  grid-row-gap: $recipe-icon-padding * 3;
  margin-bottom: $recipe-icon-padding * 3;
};

.recipeImage {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  border-radius: $icon-border-radius;
  z-index: 100;
}

.imagePlaceholder {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  background-image: url('../../assets/images/missing_image.png');
  background-size: cover;
  border-radius: $icon-border-radius;
  box-sizing: border-box;
}

.recipeItemTextContainer {
  text-align: left;
  min-width: 0; // flex box defaults to not let container be smaller than content: when we want content to shrink (e.g. text) forcing min width allows content to wrap dynamically
  padding-right: .6em;
  p {
    text-align: left;
    color: grey;
    text-transform: uppercase;
    font-size: .8em;
    margin: .5em 0;
  }
}

.newRecipe {
  position: absolute;
  z-index: 200;
  background-color: white !important;
  bottom: 15px;
  right: 15px;
  cursor: pointer !important;
  box-shadow:
  rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, 
  rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, 
  rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.completedRecipe {
  display: none;
}

@mixin recipeButton() {
  display: flex;
  height: $completed-icon-width;
  width: $completed-icon-width;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  font-size: 22px;
  color: white;
}

.completedRecipeButton {
  @include recipeButton();
  &:before {
    content: "\2713";
    transform: scaleX(1.2);
  }
  background-color: lighten($off-brand-color, 5%);
}

@media (max-width: $small-breakpoint) {
  .headline {
    padding: 1.5rem 0;
    font-size: 2rem;
  }
}

@media (max-width: $icon-grid-4) {
  .recipeItemStyles {
    grid-template-columns: repeat(4, auto);
  };
}
@media (max-width: $icon-grid-3) {
  .recipeItemStyles {
    grid-template-columns: repeat(3, auto);
  };
}
@media (max-width: $icon-grid-2) {
  .recipeItemStyles {
    grid-template-columns: repeat(2, 49%);
  };
  .recipeItemBox {
    width: 100%;
  }
  .recipeItemImageContainer {
    width: 100%;
  }
}
@media (max-width: $icon-grid-1) {
  .recipeItemStyles {
    grid-template-columns: repeat(1, 100%);
    grid-row-gap: $recipe-icon-padding * 4;
  };
  .recipeItemImageContainer {
    height: 300px;
  }
}