@import '../../sassVariables.scss';

.loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $bright-color;
}

.loadingSection {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $bright-color;
}

.loader {
    left: 50%;
    margin-left: -4rem;
    font-size: 10px;
    border: .8rem solid darken($brand-color, 25%);
    border-left: .8rem solid $off-brand-color;
    animation: spin 1.1s infinite linear;
}
.loader, .loader:after {
    top: 50%;
    border-radius: 50%;
    width: 8rem;
    height: 8rem;
    display: block;
    position: absolute;
    margin-top: -4.05rem;
}

.loaderHome, .loaderHome:after {
  top: 75%;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
