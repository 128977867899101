@import '../sassVariables.scss';

.navContainer {
  background-color: rgba(70, 70, 64, .8);
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  height: $navbar-height;
  z-index: 1000;
}

.navBar.pageContentSection {
  min-height: 0;
  padding-bottom: 0;
}

.navBar {
  img {
    height: $icon-height;
  }
  h1 {
    font-size: 2.5rem;
    margin-top: -10px;
    padding: 0;
    font-weight: 400;
    font-family: 'Pacifico', cursive;
  }
  i {
    font-size: 3rem;
    margin-right: 15px;
  }
  i:focus {
    box-shadow: none;
  }
  a {
    color: white;
  }
  height: 100%;
  display: flex;
  z-index: 500;
  justify-content: space-between;
  align-items: center;
  color: white;
  position: relative;
  z-index: 500;
  background: none;
  .fas {
    cursor: pointer;
  }
  background-color: transparent;
}

.navBarBackground {
  background-image: url('../../assets/images/header_background.jpg');
  height: $navbar-height;
  background-size: 100%;
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  position: relative;
  z-index: 250;
};

@media (max-width: $small-breakpoint) {
  .navContainer, .navBarBackground {
    height: $navbar-height-md;
    img {
      height: $icon-height-md;
    }
    h1 {
      display: none;
    }
  }
}
@media (min-width: $small-breakpoint+1) and (max-width: $medium-breakpoint) {
  .navContainer, .navBarBackground {
    height: $navbar-height-md;
    img {
      height: $icon-height-md;
    }
    h1 {
      font-size: 2.25rem;
    }
  }
}
@media (min-width: $medium-breakpoint+1) and (max-width: $large-breakpoint) {
  .navContainer, .navBarBackground {
    height: $navbar-height-md;
    img {
      height: $icon-height-md;
    }
    h1 {
      font-size: 2.25rem;
    }
  }
}
