//COLORS
$background-color: rgb(245, 245, 248);
$brand-color: lighten(#fffc4c, 5%);
$off-brand-color: #009933;
$offsetColor: darken(#3f4c6b, 25%);
$bright-color: #fff;
$logo-color: #FFDF40;
$filled-star-color: darken(gold, 7%);
$login-primary: #3f4c6b;
$input-error-color: red;

//LAYOUT SIZES
$navbar-height: 80px;
$navbar-height-md: 64px;
$header-height: 35vh;
$header-height-sm: 15vh;
$header-height-md: 25vh;
$header-height-lg: 30vh;
$margin-md: 20px;
$margin-lg: $margin-md * 2;
$small-breakpoint: 600px;
$medium-breakpoint: 900px;
$large-breakpoint: 1200px;

//ICON SIZES
$recipe-icon-size: 200px;
$recipe-icon-padding: 16px;
$icon-height: $navbar-height * .9;
$icon-height-md: $navbar-height-md * .9;
$icon-border-radius: 2%;

//HOVER GRADIENT BUTTON MIXIN
@mixin gradient-animation( $borderColor1, $borderColor2, $start1, $start2, $end1, $end2, $transTime ){
  border: 1px solid $borderColor1;
  background-size: 100%;
  background-color: $start1;
  background-image: -webkit-gradient(linear, left top, left bottom, from($start1), to($start2));
  background-image: -webkit-linear-gradient(top, $start1, $start2);
  background-image: -moz-linear-gradient(top, $start1, $start2);
  background-image: -ms-linear-gradient(top, $start1, $start2);
  background-image: -o-linear-gradient(top, $start1, $start2);
  background-image: linear-gradient(to bottom, $start1, $start2);
  z-index: 100;
  &:before {
    background-color: $end1;
    background-image: -webkit-gradient(linear, left top, left bottom, from($end1), to($end2));
    background-image: -webkit-linear-gradient(top, $end1, $end2);
    background-image: -moz-linear-gradient(top, $end1, $end2);
    background-image: -ms-linear-gradient(top, $end1, $end2);
    background-image: -o-linear-gradient(top, $end1, $end2);
    background-image: linear-gradient(to bottom, $end1, $end2);
    border-radius: inherit;
    box-sizing: border-box;
    content: '';
    display: block;
    // border: solid 1px transparent;
    height: 100%;
    position: absolute;
    top: 0; left: 0;
    opacity: 0;
    width: 100%;
    z-index: -100;
    transition: all $transTime;
  }
  &:hover, &:focus {
    border: 1px solid $borderColor2;
    &:before {
      opacity: 1;
    }
  }
}


//imagewidth: max 480 at 765 & belowB
// 320 to 1300
// back to 480 above
// 40px font above 765, 24px below
// 35 char limit on names