@import '../sassVariables.scss';

.contentContainer.login {
  background-image: url('../../assets/images/login_background.jpg');
  padding: 0;
}

.loginPage {
  margin: 0 auto;
  min-height: calc(100vh - #{$navbar-height-md});
  max-width: 1200px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/images/tablescape.jpg');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  padding: $navbar-height-md $margin-md 0 $margin-md;
  .loginDivider {
    display: flex;
    width: 110%;
    padding: 2em;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 1rem;
      font-weight: 900;
    }
    hr {
      width: calc(100% / 2 - 2rem);
      height: 0;
      border: 1px solid $background-color;
    }
  }
}

.loginBox {
  background-color: rgba(white, .99);
  margin: (-$navbar-height-md + $margin-md) 0 $margin-md 0;
  width: 100%;
  max-width: 450px;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.28);
  border-radius: 10px;
  border: solid 1px darken($background-color, 10%);
  overflow: hidden;
  .loginSection.demo {
    border-bottom: solid 2px darken($background-color, 10%);
  }
  .loginSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    padding: 1em;
    h2 {
      margin-bottom: 20px;
    }
    h3 {
      margin-bottom: 25px;
      margin-top: -10px;
    }
    .signUpLink {
      color: grey;
    }
    .loginForm {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: visible;
      div:nth-child(n-1) {
        margin-bottom: 10px;
      }
      button, .inputField {
        width: 80%;
      }
      .inputField {
        padding: .5em;
        display: flex;
        align-items: center;
        border: 1px solid darken($background-color, 20%);
        color: darken($background-color, 20%);
        background-color: white;
        border-radius: 4px;
        overflow: hidden;
        i {
          margin-right: .5em;
        }
        input[type=password],
        input[type=text],
        input[type=email] {
          border: none;
          outline: none;
          background-color: transparent;
          padding: 0;
          font-size: 16px;
          width: 100%;
        }
        input:-webkit-autofill {
          box-shadow: 0 0 0px 1000px white inset;
          -webkit-box-shadow: 0 0 0px 1000px white inset;
        }
        ::placeholder {
          color: darken($background-color, 15%);
          opacity: 1;
        }
        :-ms-input-placeholder {
          color: darken($background-color, 15%);
        }
        ::-ms-input-placeholder {
          color: darken($background-color, 15%);
        }
      }
      .inputFieldError {
        @extend .inputField;
        border: 1px solid $input-error-color;
        color: $input-error-color;
        background-color: lighten($input-error-color, 48%);
        input {
          background-color: transparent;
        }
        input:-webkit-autofill {
          box-shadow: 0 0 0px 1000px lighten($input-error-color, 48%) inset;
          -webkit-box-shadow: 0 0 0px 1000px lighten($input-error-color, 48%) inset;
        }
        ::placeholder {
          color: $input-error-color;
          opacity: 1;
        }
        :-ms-input-placeholder {
          color: $input-error-color;
        }
        ::-ms-input-placeholder {
          color: $input-error-color;
        }
      }
      .inputField:focus-within {
        color: darken($logo-color, 15%);
        border: solid 1px darken($logo-color, 15%);
        ::placeholder {
          color: darken($logo-color, 15%);
          opacity: 1;
        }
        :-ms-input-placeholder {
          color: darken($logo-color, 15%);
        }
        ::-ms-input-placeholder {
          color: darken($logo-color, 15%);
        }
      }
      .inputFieldError:focus-within {
        color: $input-error-color;
        border: solid 1px $input-error-color;
        background-color: lighten($input-error-color, 45%);
        input:-webkit-autofill {
          box-shadow: 0 0 0px 1000px lighten($input-error-color, 45%) inset;
          -webkit-box-shadow: 0 0 0px 1000px lighten($input-error-color, 45%) inset;
        }
        ::placeholder {
          color: $input-error-color;
          opacity: 1;
        }
        :-ms-input-placeholder {
          color: $input-error-color;
        }
        ::-ms-input-placeholder {
          color: $input-error-color;
        }
      }
      .loginError {
        color: red;
        font-size: .9rem;
        height: 2rem;
        margin: .2rem 0;
        font-style: italic;
        // position: absolute;
      }
    }

    //LOGIN BUTTONS
    .loginButton {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 42px;
      min-width: 233px;
      max-width: 450px;
      padding: 8px;
      border-radius: 4px;
      box-sizing: border-box;
      cursor: pointer;
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.25px;
      white-space: nowrap;
      transition: all .2s;
      -webkit-border-horizontal-spacing: 0px;
      -webkit-border-vertical-spacing: 0px;
      -webkit-box-shadow: 0px 1px 2px -2px rgba(0,0,0,0.75);
      -moz-box-shadow: 0px 1px 2px -2px rgba(0,0,0,0.75);
      box-shadow: 0px 1px 1px 1px rgba(0,0,0,0.15);
      .logo {
        width: 24px;
        height: 24px;
        border-radius: 2px;
        margin-right: 20px;
      }
      svg {
        color: $logo-color;
      }
    }
    .loginButton.facebook {
      color: #ffffff;
      @include gradient-animation(transparent, transparent, #4267B2, #4267B2, darken(#4267B2, 10%), darken(#4267B2, 10%), .3s );
      margin-top: $margin-md;
    }
    .loginButton.google {
      color: #6a737c;
      @include gradient-animation(#adb3b9, #adb3b9, #ffffff, #ffffff, darken(#fff, 5%), darken(#fff, 5%), .3s );
    }
    .loginButton.twitter {
      margin-top: $margin-md;
      color: #ffffff;
      @include gradient-animation(transparent, transparent, #1DA1F2, #1DA1F2, darken(#1DA1F2, 10%), darken(#1DA1F2, 10%), .3s );
    }
    .loginButton.demo {
      color: darken($logo-color, 50%);
      justify-content: center;
      margin-top: $margin-md + 10px;
      width: 80%;
      @include gradient-animation(darken($logo-color, 20%), darken($logo-color, 20%), $logo-color, #FEBF04, lighten($logo-color, 10%), lighten(#FEBF04, 10%), .3s );
    }
    .loginButton.email {
      @include gradient-animation(darken($login-primary, 20%), darken($login-primary, 10%), $login-primary, darken($login-primary, 15%), lighten($login-primary, 10%), darken($login-primary, 5%), .3s );
      color: white;
      justify-content: center;
      margin-top: $margin-md;
    }
  }
}

.navBarMargin {
  height: $navbar-height-md;
  width: 100%;
}

.loginBackground {
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: rgba(255, 255, 255, .5);
}

@media (min-width: $large-breakpoint + 1) {
  .loginPage {
    padding-top: $navbar-height;
    min-height: calc(100vh - #{$navbar-height});
  }
}
