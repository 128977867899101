@import '../sassVariables.scss';

//OVERALL STYLE
.recipeDetailList {
  margin-bottom: 1.5rem;
  line-height: 1.5;
  font-size: .9em;
}

.detailFlex {
  display: flex;
  flex-direction: column;
};

.directionDisplay {
  display: grid;
  grid-template: auto / 1.3fr 3fr;
  grid-column-gap: 50px;
  padding: 0 2%;
};

.emptyContentMessage {
  grid-column: 1 / 3;
  align-items: center;
  text-align: center;
  font-style: italic;
  padding: 0 1em;
  color: grey;
  &>*:nth-child(n) {
    margin-bottom: 1em;
  }
  &>*:last-child {
    margin-bottom: 3em;
  }
}
.emptyContentMessage.discoverPage {
  margin-top: 3em;
}

//MAIN RECIPE DISPLAY
.mainRecipeContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 3rem 2rem;
    .recipeDetailPicture {
      width: $recipe-icon-size * 1.5;
      height: $recipe-icon-size * 1.5;
      position: relative;
      overflow: hidden;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      box-shadow: 0px 0px 5px 0px rgba(0,0,5px,0.25);
      border-radius: 10%;
    }
    .mainDetailContainer {
      max-width: calc(100% - (#{$recipe-icon-size}*1.5));
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
      margin: 0 auto;
      padding-left: 5rem;
      h1 {
        margin-bottom: 3rem;
        max-height: 4.88em;
        line-height: 1.22em;
        overflow: hidden;
      }
    };
    .ratingBox {
      font-size: 1.4rem;
    }
  };

@media (max-width: $small-breakpoint + 100) {
  .directionDisplay {
    grid-template: auto / 1fr;
  };

  .mainRecipeContainer {
    padding: 2em 0;
    flex-direction: column;
    .recipeDetailPicture {
      width: $recipe-icon-size * 1.25;
      height: $recipe-icon-size * 1.25;
    }
    .mainDetailContainer {
      max-width: 100%;
      padding: 0 2rem;
      h1 {
        margin: 2rem 0 3rem 0;
        font-size: 1.5rem;
      }
    }
  }
}
@media (min-width: $small-breakpoint + 101) and (max-width: $medium-breakpoint) {
  .mainRecipeContainer {
    padding: 2rem 1.5rem;
    .recipeDetailPicture {
      width: $recipe-icon-size * 1.25;
      height: $recipe-icon-size * 1.25;
    }
    .mainDetailContainer {
      padding-left: 3rem;
      h1 {
        font-size: 1.5rem;
      }
      max-width: calc(100% - (#{$recipe-icon-size} * 1.25));
    }
  }
}
@media (min-width: $medium-breakpoint + 1) and (max-width: $large-breakpoint) {
  .mainRecipeContainer {
    .recipeDetailPicture {
      width: $recipe-icon-size * 1.5;
      height: $recipe-icon-size * 1.5;
    }
    .mainDetailContainer {
      padding-left: 4rem;
      max-width: calc(100% - (#{$recipe-icon-size} * 1.5));
    }
  }
}

//DELETION POPUP
.popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
  .popup-inner {
    position: absolute;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    padding: 3rem;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    border-radius: 30px;
    background: white;
    h1 {
      height: 50%;
      box-sizing: border-box;
      margin-bottom: 1rem;
    }
    .popup-buttons {
      display: flex;
    }
  }
  .popup-inner.discoverPage {
    top: auto;
    bottom: auto;
    right: auto;
    left: auto;
    padding: 2rem 4rem;
    .recipeItemImageContainer {
      margin: 1.5rem 0;
      .recipeImage {
        border-radius: 5%;
      }
    }
  }
}
@media (max-width: $small-breakpoint - 150) {
  .popup {
    .popup-inner {
      left: 8%;
      right: 8%;
      padding: 2rem;
      h1 {
        font-size: 1.5rem;
      }
      .popup-buttons {
        display: block;
      }
    }
  }
}
@media (min-width: $small-breakpoint - 149) and (max-width: $small-breakpoint + 100) {
  .popup {
    .popup-inner {
      left: 12%;
      right: 12%;
      padding: 2.5rem;
      h1 {
        font-size: 1.5rem;
      }
      .popup-buttons {
        display: block;
      }
    }
  }
}
@media (min-width: $small-breakpoint + 101) and (max-width: $medium-breakpoint) {
  .popup {
    .popup-inner {
      h1 {
        font-size: 1.5em;
      }
    }
  }
}
@media (min-width: $medium-breakpoint + 1) and (max-width: $large-breakpoint) {

}
