@import './sassVariables.scss';

//Overarching stylesheet
.siteContainer {
  margin: 0 auto;
  width: 100%;
  background: $background-color;
  box-sizing: border-box;
  position: relative;
  z-index: 100;
  min-height: 100vh;
}

.contentContainer {
  position: relative;
  min-height: calc(100vh - #{$navbar-height});
  box-sizing: border-box;
};

.pageContentSection {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  min-height: calc(100vh - #{$navbar-height}  - #{$margin-lg});
  padding: $margin-lg;
  padding-top: 0;
  p {
    text-align: center;
  }
}

.clickEvent {
  cursor: pointer;
}

.pageContentSection.headerPage {
  min-height: calc(100vh - #{$header-height} - #{$navbar-height}  - #{$margin-lg});
}

@media (max-width: $small-breakpoint) {
  .pageContentSection {
    padding: 0 $margin-md $margin-md * 2 $margin-md;
    min-height: calc(100vh - #{$navbar-height-md});
  }
  .pageContentSection.headerPage {
    min-height: calc(100vh - #{$header-height-sm} - #{$navbar-height-md});
  }
}
@media (min-width: $small-breakpoint + 1) and (max-width: $medium-breakpoint) {
  .pageContentSection {
    padding: 0 $margin-md $margin-md * 3 $margin-md;
    min-height: calc(100vh - #{$navbar-height-md});
  }
  .pageContentSection.headerPage {
    min-height: calc(100vh - #{$header-height-md} - #{$navbar-height-md});
  }
}
@media (min-width: $medium-breakpoint + 1) and (max-width: $large-breakpoint) {
  .pageContentSection {
    padding: 0 $margin-md $margin-md * 3 $margin-md;
    min-height: calc(100vh - #{$navbar-height-md});
  }
  .pageContentSection.headerPage {
    min-height: calc(100vh - #{$header-height-lg} - #{$navbar-height-md});
  }
}
@media (min-width: $large-breakpoint + 1) {
  .contentContainer {
    padding-bottom: $margin-lg;
  };
}

//'Select' items prevent highlighting of dropdown on click
i {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
