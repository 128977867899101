@import '../../sassVariables.scss';

@mixin navButtonStyle() {
  color: $bright-color !important;
  margin: .8rem !important;
}

.button-green {
  background: $off-brand-color !important;
  &:hover {
    background: darken($off-brand-color, 5%) !important;
  }
  @include navButtonStyle();
}

.button-red {
  background: red !important;
  &:hover {
    background: darken(red, 5%) !important;
  }
  @include navButtonStyle();
}

.button-white {
  background: white !important;
  color: black !important;
  &:hover {
    background: darken(white, 10%) !important;
  }
}

.linkStyle {
  color: $bright-color;
  border: none;
  text-decoration: none;
  font-weight: 600;
}

.navButtonStyle:focus {
  outline: none;
  box-shadow: 0px 0px 14px -2px rgba(0,0,0,0.75);;
}
.navButtonStyle:hover {
  cursor: pointer;
}

.navButtonStyle.button-green:active {
  background: darken($off-brand-color, 10%);
}
.navButtonStyle.button-red:active {
  background: darken(red, 10%);
}

@media (max-width: $small-breakpoint) {
  .navButtonStyle {
    font-size: 1rem;
    margin: .9rem;
  }
}
@media (min-width: $small-breakpoint+1) and (max-width: $medium-breakpoint) {
  .navButtonStyle {
    font-size: 1rem;
    margin: .9rem;
  }
}
@media (min-width: $medium-breakpoint+1) and (max-width: $large-breakpoint) {
  .navButtonStyle {
    font-size: 1rem;
    margin: 1rem;
  }
}

//Logout Button Styles
.loginLogout {
  @extend .navButtonStyle;
  background: none;
  border: .15em solid $bright-color;
  padding: .5em;
  margin: 0;
}
