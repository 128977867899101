@import '../../sassVariables.scss';

$filter-padding: 10px;

.filterBox {
  text-align: center;
  font-weight: 400;
  h3 {
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.filterGrid {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-template-rows: auto;
  justify-content: center;
  grid-column-gap: $filter-padding;
  grid-row-gap: $filter-padding;
  margin: $filter-padding;
  .MuiButtonBase-root {
    width: 115px;
  }
}

@media (max-width: $small-breakpoint) {
  .filterGrid {
    grid-template-columns: repeat(2, auto);
  }
}
@media (min-width: $small-breakpoint+1) and (max-width: $medium-breakpoint) {
  .filterGrid {
    grid-template-columns: repeat(4, auto);
  }
}
