@import '../sassVariables.scss';

ul, ol {
   padding-left: 1.8rem;
   padding-bottom: 1rem;
}

.formLayout {
 min-width: 200px;
 max-width: 70%;
 margin: 0 auto;
}

.formInputLayout {
 margin: 2rem auto;
 display: flex;
 flex-direction: column;
 textarea {
   height: 12rem;
  }
}

.centerMe {
 margin: 0 auto;
 text-align: center;
}

.inputError {
  border: 1px solid $input-error-color;
  padding: 2px;
  background: lighten($input-error-color, 48%);
  &:focus {
    border: 1px solid transparent;
    outline: $input-error-color auto 5px;
    box-shadow: $input-error-color auto 5px;
    -webkit-box-shadow: $input-error-color auto 5px;
  }
}

.errorMessage {
  padding-left: 1rem;
  color: $input-error-color;
  font-style: italic;
}
.noErrorMessage {
  display: none;
}
.inputFieldNote {
  padding-left: 1rem;
  color: grey;
  font-style: italic;
}

.ratingSection {
  display: flex;
  height: 2rem;
  font-size: 1.5rem;
  align-content: center;
  box-sizing: border-box;
  label {
    margin-right: 1rem;
    align-self: center;
    font-size: 1rem;
  }
}
