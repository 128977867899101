@import '../../sassVariables.scss';

$modal-primary: transparent;
$dark-grey: #333333;
$menu-padding: 20px;
$base-transition: .3s;

.menuModal {
  background-color: $modal-primary;
  background-image: url('../../../assets/images/wood_panel.png');
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  right: 100%;
  left: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-size: 2.5em;
  text-align: center;
  overflow: auto;
  z-index: 200;
  transition: all $base-transition;
  h3, i, p, button {
    color: $offsetColor;
    background-color: white;
    margin: 0;
  }
   button {
     font-size: 1.2rem;
     padding: .75rem;
   }
  a {
    font-size: 1rem;
  }
  .modal-top {
    background-color: $modal-primary;
    padding-right: 10%;
    text-align: right;
    i {
      cursor: pointer;
      font-size: 2rem;
      box-sizing: border-box;
      padding: .4rem;
      margin: 1rem 0;
      border-radius: 50%;
    }
  }
  .modal-body {
    min-height: calc(100% - 77px);
    background-color: $modal-primary;
  }
}

// Prevent Body from Scrolling Behind Modal (does not apply to Mobile) :
.modal-open {
  overflow: hidden;
  position: relative;
}

//General Accoridan Styles
.accordion-list {
  height: 100%;
  .accordion-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all .4s;
    padding: $menu-padding/2 10%;
    border-bottom: 1px solid darken($modal-primary, 10%);
    position: relative;
    i {
      font-size: 2rem;
      margin-left: .5em;
    }
    .accordion-label, .accordion-submenus {
      align-self: flex-start;
      text-align: left;
    }
    .accordion-label {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .accordion-submenus {
      width: 100%;
      box-sizing: border-box;
      max-height: 0;
      transition: all $base-transition/2;
      overflow: hidden;
      position: relative;
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: auto;
      p, a {
        place-self: start;
        white-space: normal;
        margin-top: .5em;
      }
    }
  }
  .accordion-item.accordion-open {
    background-color: darken($modal-primary, 10%);
    border-bottom: 1px solid darken($modal-primary, 15%);
    .accordion-submenus {
      max-height: 300px; //Not responsive: better transition than height? -LV
      padding: .25em;
    }
  }
  .accordion-footer {
    width: 100%;
    height: auto;
    & > * {
      margin-bottom: 30px;
    }
    p, a {
      white-space: normal;
    }
  }
}

//NavBar Accordion Styles
.menuModal.modal-open-style {
  transform: translateX(-100%);
  -webkit-box-shadow: -1px 0px 10px 1px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 0px 10px 1px rgba(0,0,0,0.75);
  box-shadow: -1px 0px 10px 1px rgba(0,0,0,0.75);
}

@media (min-width: $small-breakpoint) {
  .menuModal {
    width: 350px;
    right: -350px;
    .modal-top {
      padding-right: 25px;
    }
    .accordion-item {
      padding: .25em 25px;
      .accordion-label {
        align-self: center;
      }
    }
  }
  .menuModal.modal-open-style {
    transform: translateX(-100%);
  }
}

.screen-blocker {
  position: fixed;
  box-sizing: border-box;
  transform: translateY(0%);
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 195;
  transition: background-color $base-transition;
  background-color: transparent;
  content: "";
}
.screen-blocker.main-menu-showing {
  background-color: rgba(75, 75, 75, 0.3);
  pointer-events: auto;
}

.menu-arrow {
  position: relative;
  transition: all $base-transition;
}
.menu-arrow.view-icon {
  transform: rotate(180deg);
}
