@import '../../sassVariables.scss';

.searchModal {
  height: 50vh;
}

/*Resize the wrap to see the search bar change!*/
.searchBox {
  border: none;
  width: 30%;
  height: 43px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: $navbar-height / 2;
  -webkit-box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.4);
  box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.4);
  border-radius: 35px;
  padding: 0 10px;
  overflow: hidden;
  background-color: white;
  & > p, input {
    font-size: 20px;
  }
  p:-webkit-autofill, input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }
  &:hover {
    cursor: pointer;
  }
  transform: translate(-50%, -50%);

  .searchButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 40px;
    padding: 0;
    height: 100%;
    text-align: center;
    color: $offsetColor;
    border: none;
    font-size: 25px;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  .searchInput {
    width: 100%;
    padding: 10px;
    border: none;
    outline: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:focus{
      color: lighten($offsetColor, 10%);
    }
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $offsetColor;
      opacity: 1; /* Firefox */
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $offsetColor;
    }
    &::-ms-input-placeholder { /* Microsoft Edge */
      color: $offsetColor;
    }
  }
}

.searchBoxModal {
  input {
    font-size: 1.3rem;
  }
  button {
    background-color: rgba(0,0,0,0);
    border: none;
    font-size: 1.3rem;
  }
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;
}

.closeIcon {
  position: absolute !important;
  top: 5px;
  right: 5px;
  z-index: 200;
  cursor: pointer;
  font-size: 2rem !important;
  height: 46px;
}

@media (max-width: $small-breakpoint) {
  .searchBox {
    width: 70%;
  }
  .searchModal {
    height: 100vh;
    padding-top: 20px;
  }
  .searchBoxModal {
    padding-top: 20px;
  }
}
@media (min-width: $small-breakpoint+1) and (max-width: $medium-breakpoint) {
  .searchBox {
    width: 50%;
  }
}
@media (min-width: $medium-breakpoint+1) and (max-width: $large-breakpoint) {
  .searchBox {
    width: 40%;
  }
}
@media (max-width: $large-breakpoint) {
  .searchBox {
    margin-top: $navbar-height-md / 2;
  }
}

// React Material Search Button

.MuiFab-root {
  margin: 0px !important;
  top: auto !important;
  right: 20px !important;
  bottom: 20px !important;
  left: auto !important;
  position: fixed !important;
  z-index: 10000;
};